@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono&display=swap");

.container-background {
  background: linear-gradient(
    248.87deg,
    rgba(242, 242, 242, 0.51) 9.69%,
    rgba(242, 242, 242, 0.75) 91.3%
  );
}
.sidebar-menu {
  @apply text-sm h-12 pl-3 flex items-center justify-between border-transparent hover:text-red-500 hover:bg-red-100 cursor-pointer;
}
.sidebar-menu-selected {
  @apply text-sm h-12 pl-3 flex items-center justify-between border-transparent text-red-500 bg-red-200 cursor-pointer;
}
.btn {
  @apply hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded w-full text-sm;
}
.heading {
  /* font-family: "Test Söhne Schmal"; */
  font-size: 128px;
  line-height: 115px;
  /* or 115px */

  text-transform: uppercase;

  background: linear-gradient(147.67deg, #e900ff 0%, #16e8ff 80.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.gradient-button {
  border: 2px solid;

  border-image-source: linear-gradient(
    270.04deg,
    #16e8ff 0.03%,
    #e900ff 99.97%
  );
}

@font-face {
  font-family: "Test Sohne Schmal";
  src: url("./assets/fonts/TestSohneSchmal/test-soehne-schmal-kraftig.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

.modalStyle .ant-modal-content,
.modalStyle .ant-modal-header {
  padding: 0%;
  width: 0%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wrap {
  border-radius: 2em !important;
  padding: 0em !important;
}
.searchScrollbar::-webkit-scrollbar {
  border-radius: 20px;
  height: 0.5rem;
  width: 0.2rem;
}
.searchScrollbar::-webkit-scrollbar-thumb {
  background-color: #cfd3de;
  border-radius: 20px;
}
.searchScrollbar::-webkit-scrollbar-track {
  background-color: #f6f5fb;
}
 .select-pod.ant-select:not(.ant-select-customize-input) .ant-select-selector  { 
  width: 100px;
  margin-top: 4px;
  background-color: #f3f4f6;
  border: none;
  outline: none;
  border-radius: 4px;
  height: 40px;
  padding: 0px 11px;
 }
.select-pod.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  margin-top: 5px;
}
.selectFilterStatus.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: #f3f4f6;
  border: 0.5px solid #6b7280;
  border-radius: 4px;
}
.select-pod.ant-select .ant-select-arrow {
  padding-top: 2mm;
  color: #6B7280;
}
.btn-hover{
background: linear-gradient(273.19deg, #16E8FF -18.02%, #E900FF 88.1%);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(4.5px);
  border-radius: 84px;
}
.btn-hover:hover{
  background: linear-gradient(273.2deg, #16E8FF 10.88%, #E900FF 94.01%);
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
  backdrop-filter: blur(4.5px);
  border-radius: 84px;
}

.dashboard-select.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #F7F7F7;
  border: 0.5px solid #6B7280;
  outline: none;
  border-radius: 4px;
  font-size: 18px;
  height: 40px;
  padding-left: 16px;
}

.dashboard-select.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  margin-top: 0px;
}

.dashboard-select.ant-select .ant-select-arrow {
  color: #6B7280;
}
::placeholder {
  text-transform: none;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
text-transform: none;
}

::-ms-input-placeholder { /* Microsoft Edge */
text-transform: none;
}

.borderedSelect {
  border-top-left-radius: 50px !important;
  border-bottom-left-radius: 50px !important;
  background-color: white;
  border: 1px solid #E0E0E0;
  padding: 3px;                
  outline: none;
}
.custom-upload .ant-upload-list-item-action {
  display: none;
  /* This will hide the delete icon */
}
#cube { display: none; }
#container:hover #cube { display: block; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}